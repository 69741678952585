/* eslint-disable */
import Vue from "vue";
import { mapState } from "vuex";
import { BImg, BCollapse } from "bootstrap-vue";
//import VueParticles from "vue-particles";
import { particlesSetup } from "@/utils/setup";
import Subscriptions from "@/views/work/admin/adminsubscription/adminsubscription.vue";
//import { getAssignmentsByUser } from "@/services/api/assignment";

export default {
  data() {
    return {
      loading: false,
      showTrackingBar: false, //move to Vuex store later...
      showOverlay: false,
      modalOpen: "",
      showCategorySelectArea: true,
      categories: [
        {
          id: 1,
          name: "Søk i registreringer",
          img: "/img/data-symbols-into-a-funnel.png",
          icon: "las la-search",
          routeName: "FindCases"
        },
        {
          id: 2,
          name: "Ledig kapasitet",
          img: "/img/package.png",
          icon: "las la-truck-loading",
          routeName: "capacity"
        },
        {
          id: 3,
          name: "Oppdrag som trenger kapasitet",
          img: "/img/livingroom-black-double-sofa.png",
          icon: "las la-box-open",
          routeName: "NeedCapacity"
        },
        
      ],
      particlesSetup: particlesSetup,
      companyId: this.$route.params.companyId || null,
    };
  },
  computed : {
    ...mapState("auth",{
      isAuthenticated: state => state.authenticated
    })
  },
  watch : {
    isAuthenticated: async function(newValue) {
      if(newValue===true) {
        //let assignmentList = await getAssignmentsByUser();
        console.info("MY Assignments:",assignmentList);
        //this.$store.dispatch("assignment/setAssignmentList", assignmentList);
      }
    }
  },
  components: {
    "b-img": BImg,
    "b-collapse": BCollapse,
    "subscriptions": Subscriptions,
    //"vue-particles": VueParticles
  },
  i18n: {
    /* messages: {
        en: {"tr": i18nCodes_en},
        no: {"tr": i18nCodes_no}
      }  */
  },
  created() {
    console.info("CREATED", this.particlesSetup);
  },
  mounted() {
    if (this.companyId === null) {
      //check if exclusiveCompanyId is set in Store
      this.companyId = this.$store.getters["exclusiveCompanyId"];
    } else {
      //Set new exclusiveCompanyId in store
      this.$store.dispatch("account/setExclusiveCompanyId", this.companyId);
    }
  },
  methods: {
    setRoute(routeName) {
      this.$router.push({"name": routeName});
    }
  }
};
